.navbar{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px 10px;
  background-color: #ffffff;
}

.titulo{
  display: flex;
  align-items: center;
  gap: 20px;
}

.listLinks{
  display: flex;
  list-style-type: none;
}

.listLinks a{
  color: #000;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 20px;
  transition: background 600ms ease;
  margin-right: 5px;
}

.listLinks a:hover{
  color: #800040;
  background: #d6d6d6;
}

.nestListLinks li{
  list-style: none;
  margin: 5px 0px;
}

@media (max-width: 720px) {
  .navbar{
    display: block;
    align-items: center;
    padding: 10px 10px;
    background-color: #ffffff;
  }

  .listLinks{
    width: 100%;
    display: inline-block;
    list-style-type: none;
    text-align: center;
  }

  .logoCenter {
    text-align: center;
  }
}