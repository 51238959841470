.divpdf {
    width: 100%;
    text-align: center;
    align-items: center;
  }

.divdocument{
    display: block;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.botonnaranja{
    background-color: #e78b00;
    color: #FFFF;
    font-size: 18px;
    border-radius: 8px;
    margin: 5px;
    padding: 5px;
}
.botonnaranja:hover {
    background-color: #ec7c26;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  }
  
@media (max-width: 400px) {
    .section {
        width: 100%;
    }
}
