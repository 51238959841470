*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body{
  background-color: #f4f5f6;
}

.isActive{
  font-weight: bold;
  color: #800040!important;
}

.example-container {
  align-items: center;
  display: flex;
  height: 350px;
  justify-content: center;
  position: relative;
  width: 100%;
}

@media (max-width: 720px) {
  .example-container{
    height: 250px;
  }
}

.next,
.prev {
  align-items: center;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  font-size: 22px;
  font-weight: bold;
  height: 50px;
  justify-content: center;
  position: absolute;
  user-select: none;
  width: 50px;
  z-index: 2;
}

.next {
  right: 10px;
}

.prev {
  left: 10px;
}

.example-container img {
  position: absolute;
  max-height: 100%;
  max-width: 100%;
}

