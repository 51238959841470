.contenedor {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
}

.titulo{
    font-size: 30px;
    margin-top: 30px;
    margin-bottom: 15px;
}

.headerArea{
    width: 100%;
}

.area{
    font-size: 18px;
    margin: 15px 20%;
    text-align: left;
}

.descArea{
    font-size: 18px;
    margin: 5px 20%;
    text-align: justify;
}

.equipoImg {
    margin-top: 30px;
    height: 600px;
}

.equipoDesc {
    font-size: 18px;
    margin: 15px 20%;
    text-align: center;
}

@media (max-width: 720px) {
    .equipoImg {
        height: 250px;
    }

    .equipoDesc {
        font-size: 14px;
        margin: 15px 5%;
    }

    .area{
        font-size: 18px;
        margin: 15px 15px;
        text-align: center;
    }

    .descArea{
        font-size: 14px;
        margin: 15px 5%;
    }
}