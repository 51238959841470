.containerWrap {
  display: flex;
  flex-wrap: wrap;
  font-size: 18px; 
}

.tesisTable {
  width: 85%;
}

.infoTesis p {
  padding: 5px;
  text-align: left;
}

.proyectoResumen {
  text-align: justify;
  width: 45%;
}

.proyectoResponsables{
  text-align: left;
  width: 25%;
}

@media (max-width: 768px) {
  .containerWrap {
    display: flex;
    flex-wrap: wrap;
  }

  .tesisTable {
    width: 80%;
  }

  .proyectoResumen {
    width: 100%;
  }
  
  .proyectoResponsables{
    width: 100%;
  }

  .infoTesis p {
    padding: 5px;
    text-align: left;
    font-size: 12px;
  }
}
