.header{
  padding-top: 600px;
  background: rgba(0, 0, 0, 0.2) url(../images/Equipo4.JPG);
  height: 650px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  background-blend-mode: darken;
  background-attachment: fixed;
}

.logos{
  background-color: #fff;
  display: flex;
  padding-left: 10px;
  padding-top: 12px;
}
.logos img{
  margin-left: 10px;
  height: 40px;
}

.listLinks a{
  color: #000;
  padding: 5px 10px;
  border-radius: 20px;
  margin-right: 5px;
  font-size: 19px;
  text-decoration: none;
  transition: all 600ms ease;
}
 
.listLinks a:hover{
  color: #800040;
  background: #d6d6d6;
}

.nestListLinks li{
  list-style: none;
  margin: 5px 0px;
}

.navbar{
  display: flex;
  justify-content: center;
  padding-top: 1px;
}

@media (max-width: 720px) {
  .navbar{
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
}

.listLinks{
  display: flex;
  list-style-type: none;
  background-color: #fff;
  width: 100%;
  justify-content: center;
  padding: 18px 0;
}

@media (max-width: 720px) {
  .logos{
    width: 100%;
  }

  .listLinks{
    flex-direction: column;
    list-style-type: none;
  }
  .listLinks li{
    margin-bottom: 4px;
    text-align: center;
  }
  .listLinks a{
    font-size: 18px;
  }
}

.headerContenedor{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: calc(150px/6);
  text-shadow: black 0.1em 0.1em 0.2em;
}

@media (max-width: 720px) {
  .headerContenedor{
    margin: 0 10px;
    flex-direction: column;
  }
}

.headerImages{
  background-color: rgba(255, 255, 255, 0.4);
  padding: 20px;
  border-radius: 10px;
  margin-right: 30px;
}

@media (max-width: 720px) {
  .headerImages{
    margin: 20px 0;
    padding: 10px;
  }
}

.headerTexto{
  color: white;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
}

.headerSombra{
  filter:drop-shadow(black 0.1em 0.1em 0.2em);
}

.contenedorHomepage{
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.homepageObjetivos{
  margin-bottom: 30px;
}

.homepageSubtitulo{
  text-align: center;
  margin-bottom: 30px;
  font-size: 30px;
  letter-spacing: -1px;
  margin-top: 30px;
}

.homepageListUl{
  display: flex;
  flex-direction: column;
  list-style-type: disc;
  margin-left: 30px;
  font-size: 18px;

}
@media (max-width: 720px) {
  .homepageListUl{
    font-size: 14px;
  }
}

.homepageLineas{
  display: flex;
  justify-content: center;
}

@media (max-width: 720px) {
  .homepageLineas{
    margin: 0 10px;
  }
}

.lineasSubtitulo{
  font-size: 18px;
  margin-bottom: 5px;
}

@media (max-width: 720px) {
  .homepageSubtitulo{
    margin: 15px 5px;
  }
}

.homepageFotos{
  display: flex;
  flex-direction: column;
  margin: 0 120px;
}

@media (max-width: 720px) {
  .homepageFotos{
    margin: 0 20px;
  }
}

.homepageGrupo{
  font-size: 18px;
}

@media (max-width: 720px) {
  .homepageGrupo{
    font-size: 14px;
    margin-left: 20px;
  }
}
.link{
  
  color: #000;
  font-size: 18px;
}
@media (max-width: 720px) {
  .link{
    
    color: #000;
    font-size: 14px;
  }
}

.footer{
  margin-top: 90px;
  width: 100%;
  height: 220px;
  background-color: #a57e2d;
  color: #fff;
  padding: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center; 
}

.footer img{
  height: 130px;
}

.header_lab{
  margin-top: 10px;
  width: 100%;
  height: 120px;
  background-color: #a57e2d;
  color: #fff;
  padding: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center; 
}

.header_lab img{
  height: 100px;
}

@media (max-width: 720px) {
  .footer{
    flex-direction: column;
    height: 380px;
    font-size: 14px;
  }

  .footer img{
    height: 90px;
  }
}
/*Menu de hamburguesa*/

.barsMenu{
  display: none;
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-bottom: 1px;
}

@media (max-width: 720px) {
  .barsMenu{
    display: block;
  }
}

.barsMenu span{
  display: block;
  width: 100%;
  height: 2px;
  background: #ffffff;
  margin-top: 6px;
  transform-origin: 0px 100%;
  transition: all 300ms;
}

.activeline1Bars{
  transform: rotate(45deg) translate(-2px, 1px);
}

.activeline2Bars{
  opacity: 0;
  transform: translateX(-20px);
}

.activeline3Bars{
  transform: rotate(-45deg) translate(-4px, 2px);
}

.activelist{
  transition: all 600ms;
  opacity: 0;
  transform: translateX(-190px);
}

.BackToTop{
  cursor: pointer;
  border-radius: 10px;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  transition: all 300ms ease;
}

.eventospageParrafo{
  font-size: 18px;
  margin: 15px 12.5%;
  text-align: center;
}

@media (max-width: 720px) {
  .eventospageParrafo{
    font-size: 14px;
    margin: 0px 5%;
  }
}

.estanciasImg {
  width: 250px;
  height: 300px;
  border-radius: 10px;
}

table {
  width: 80%;
  border-collapse: collapse;
  margin: 20px auto;
}

table, th, td {
  background-color: #E0E3E8;
  font-size: 18px;
  border: 1px solid #b3b3b3;
  padding: 20px;
  text-align: center;
}

th {
  font-size: 20px;
  background-color: #C6C6C6;
}

@media only screen and (max-width: 600px) {
  table {
    width: 95%;
  }
  
  th, td {
    display: block;
    font-size: 14px;
  }
  
  th {
    text-align: center;
  }
}

td:hover {
  transition: 1s;
  background-color: #C6C6C6;
}

.descRow {
  width: 700px;
}

@media (max-width: 600px) {
  .descRow {
    width: 100%;
  }
  
  .logoCenter {
    text-align: center;
  }
}

.flexContainer{
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.flexGroup{
  display: flex;
  justify-content: center;
}

.galeriaImgMain {
  margin: auto;
  width: 968px;
  height: 550px;
  object-fit: cover;
  border-radius: 10px;
}

.galeriaImgSub {
  margin: auto;
  width: 800;
  height: 400px;
  object-fit: cover;
  border-radius: 10px;
}

.galeriaImgSub2 {
  margin: auto;
  width: 700px;
  height: 400px;
  object-fit: cover;
  border-radius: 10px;
}

.galeriaContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 10%;
}

.slideshow-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mySlides {
  display: none;
}

@media (max-width: 600px) {
  .flexContainer{
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
  }
  
  .flexGroup{
    display: flex;
    justify-content: center;
    margin: 0 3%;
  }
  
  .galeriaImgMain {
    width: 100%;
    height: auto;
  }
  
  .galeriaImgSub {
    width: 100%;
    height: auto;    
  }
  
  .galeriaImgSub2 {
    width: 100%;
    height: auto;
  }
  
  .galeriaContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 3%;
  }
  
}
