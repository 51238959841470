
.footer_ipn {
    background-color: #333;
    color: #fff;
  }

  .footer_ipn p {
    color: #CCC;
    font-weight: 400;
    font-size: large;
    padding: 5px 0;
}

.footer_ipn div {
    color: #CCC;
    font-weight: 100;
    font-size: small;
    padding: 5px 0;
}
