.cuadroTrabajo{
    width: auto;
    height: auto;
    border-radius: 15px;
    border: 2px solid #d6d6d6;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    margin: auto 14% auto 14%;
    padding: 1rem 2rem;
    word-wrap: break-word;
    margin-bottom: 10px;
    transition: all 500ms ease;
    
  }
  .cuadroTrabajo a:link,.cuadroTrabajo a:visited {
    text-decoration: none;
    color: black;
    transition: all 500ms ease;
  }

  .cuadroTrabajo:hover{
    border: 2px solid #09f;
    color: #09f;
  }
  .cuadroTrabajo:hover a{
    color: #09f;
    
  }
  .cuadroTrabajo p{
    margin: 0;
    
    text-align: left;
  }
  
  .cuadroTrabajo div img{
    border-radius: 8px;
    height: auto;
    width: 50%;
  }
  .cuadroTrabajo div{
    width: 23%;
    margin: 1%;
    text-align: center;
   
  }
  .subtitulo h3{
    font-size: 25px;
    margin-bottom: 5px;
  }
  
  @media (max-width: 720px) {
    .cuadroTrabajo{
      width: 18rem;
      height: auto;
      flex-flow: column wrap;
    }
    .cuadroTrabajo div{
      width: 100%;
      height: auto;
    }
  }